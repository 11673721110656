import React, { Component, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import logo from './assets/frontend/img/loading.gif';
import 'react-toastify/dist/ReactToastify.css';
import './assets/common/scss/_custom.scss';

// Admin layout container
const AdminLogin = React.lazy(() =>
  import("./components/admin/Login")
);

// Admin layout container
const AdminLayout = React.lazy(() =>
  import("./containers/AdminLayout")
);

// Frontend layout container
const FrontLayout = React.lazy(() =>
  import("./containers/FrontLayout")
);

const App = (props) => {
  const loading = () => (
    <div className="animated fadeIn pt-3 text-center">
      <img className="loader" alt="loader" title="" src={logo} />
    </div>
  );

  return (
    <BrowserRouter>
      <Suspense fallback={loading()}>
        <Switch>
          <Route path="/admin/login" render={(props) => <AdminLogin {...props} />} />
          <Route path="/admin/*" render={(props) => <AdminLayout {...props} />} />
          <Route path="/" render={props => <FrontLayout {...props} />} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
